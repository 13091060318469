import React from 'react'

import Head from 'next/head'

/**
  Generates SEO metadata

  @param {string} seoTitle - The title of the page for SEO purposes
  @param {string} seoDescription - A short description of the page for SEO purposes
  @param {number} estimatedReadingTime - The estimated reading time of the page in minutes
  @param {string} articleModifiedTime - (Optional) The last modified time of the page, defaults to the current time if not provided
  @param {string} ogImageUrl - (Optional) The URL of the image to use for OpenGraph metadata
  @returns {JSX.Element} - A JSX element containing the generated SEO metadata
*/
export const generateSEO = (seoTitle: string, seoDescription: string, estimatedReadingTime: number, articleModifiedTime?: string | null, ogImageUrl?: string | null) => {
  if (!articleModifiedTime) {
    articleModifiedTime = new Date().toISOString()
  }

  return (
    <Head>
      <title>{seoTitle}</title>
      <meta name="description" content={seoDescription} />

      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={seoTitle} />
      <meta property="og:description" content={seoDescription} />
      {/* <meta property="og:url" content={`${host}/blog/`}/> */}
      <meta property="og:site_name" content="Zremax" />
      <meta property="article:publisher" content="https://www.facebook.com/ZremaxDB" />
      <meta property="article:modified_time" content={articleModifiedTime} />
      <meta property="og:image" content={ogImageUrl || 'https://zremax.com/images/social-media-logo.png'}/>
      <meta property="og:image:width" content="1280" />
      <meta property="og:image:height" content="720" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@ZremaxD" />
      <meta name="twitter:label1" content="Est. reading time" />
      <meta name="twitter:data1" content={estimatedReadingTime + ' minutes'} />
    </Head>
  )
}

/**
  Generates a title for a portal page
  @param {string} title - The title of the portal page
  @returns {JSX.Element} - A JSX element containing the generated portal metadata title
*/
export const portalMetaTitle = (title: string) => {
  return (
    <Head>
      <title>Portal: {title} - Zremax</title>
    </Head>
  )
}
